
import { Component, Prop, Watch } from 'vue-property-decorator';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import PwrVue from '@/components/PwrVue';
import './EditorJs/EditorJsEditorStyles.scss';
import EditorJsHelper from '@/components/Op/Text/EditorJs/EditorJsHelper';
import { EditorState } from '@/components/Op/Text/EditorJs/types';

@Component
export default class OpTextViewer extends PwrVue {
  @Prop({ required: true }) id!: string;
  @Prop() content!: string | undefined;

  private editor: EditorJS | null = null;
  private editorState = EditorState.INIT;

  private async mounted(): Promise<void> {
    this.editor = new EditorJsHelper().getNewEditorJsInstance(
      this.editorId,
      this.parsedContent,
      true,
      undefined,
      ''
    );

    try {
      await this.editor.isReady;
      this.editorState = EditorState.READY;
      this.onContentChange();
    } catch (reason) {
      this.editorState = EditorState.ERROR;
    }
  }

  get editorId(): string {
    return `editor-viewer-${this.id}`;
  }

  get parsedContent(): OutputData | undefined {
    if (this.content) {
      try {
        return JSON.parse(this.content);
      } catch (error: unknown) {
        return {
          blocks: [
            {
              type: 'paragraph',
              data: { text: this.content }
            }
          ]
        };
      }
    }

    return undefined;
  }

  // Looks for content change to reinitialize editor if the update was from outside.
  @Watch('content', { deep: true })
  private onContentChange(): void {
    if (this.content) {
      const data = this.parsedContent;

      if (data) {
        this.editor?.render(data);
      }
    }
  }
}
