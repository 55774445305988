
import { Component, Prop } from 'vue-property-decorator';
import { isString } from 'lodash';
import PwrVue from '@/components/PwrVue';
import Offer, { textFields as offerTextFields } from '@/models/Offer';
import OpRodo from '@/components/Op/Rodo/OpRodo.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrFilesList from '@/components/Pwr/PwrFiles/PwrFilesList.vue';
import OpTextViewer from '@/components/Op/Text/OpTextViewer.vue';

@Component({
  components: { OpTextViewer, PwrFilesList, PwrBtn, OpRodo }
})
export default class OpOfferPreview extends PwrVue {
  @Prop() offer!: Offer;
  @Prop() isEdit!: boolean;

  private getTime(date: string): string | null {
    const split = date.split(' ');
    if (split.length > 1) {
      return split[1];
    }

    return null;
  }

  private getOfferPropByKey(key: string): string | number | undefined {
    if (this.offer) {
      // @ts-ignore
      return this.offer[key as keyof Offer];
    }
    return undefined;
  }

  get offerTextProps(): string[] {
    if (this.offer) {
      const scope: string[] = [
        'description',
        'tasks',
        'weRequire',
        'requiredDocuments',
        'additionalInfo',
        'weOffer'
      ];

      scope.filter(
        (element: string) =>
          offerTextFields.includes(element as keyof Offer) &&
          isString(this.getOfferPropByKey(element))
      );

      return scope;
    }
    return [];
  }
}
