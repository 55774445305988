
import { Component, Prop } from 'vue-property-decorator';
import { PwrFile } from '@/components/Pwr/PwrFiles/types';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import download from '@/assets/pwr/icons/download.svg';

@Component({ components: { PwrBtn } })
export default class PwrFilesList extends PwrVue {
  @Prop() files!: PwrFile[];
  @Prop({ default: () => 'default' }) source!: string;

  get filesFilteredByGroup(): PwrFile[] {
    return this.files.filter((file: PwrFile) => file.source === this.source);
  }

  get downloadIcon() {
    return download;
  }
}
