import EditorJS, { OutputData } from '@editorjs/editorjs';

// All tools for editor: https://github.com/orgs/editor-js/repositories?type=all
// @see https://github.com/editor-js/header
// @ts-ignore
// import Header from '@editorjs/header'; // Disabled for now
// @see https://github.com/editor-js/inline-code
// @ts-ignore
import InlineCode from '@editorjs/inline-code'; // Disabled for now
// @see https://github.com/editor-js/code
// @ts-ignore
// import CodeTool from '@editorjs/code'; // Does not work out of the box, sanitization required
// @see https://github.com/editor-js/underline
// @ts-ignore
import Underline from '@editorjs/underline';

// @see https://github.com/editor-js/delimiter
// @ts-ignore
import Delimiter from '@editorjs/delimiter';

// @see https://github.com/editor-js/delimiter
// @ts-ignore
import List from '@editorjs/nested-list';

// @see https://github.com/editor-js/table
// @ts-ignore
import Table from '@editorjs/table';

// @see https://github.com/editor-js/table
// @ts-ignore
// import List from '@editorjs/list';

export default class EditorJsHelper {
  public getNewEditorJsInstance(
    editorId: string,
    initialData: OutputData | undefined,
    readOnly: boolean = false,
    onChangeCallback: (() => any) | undefined = undefined,
    placeholder: string | undefined = undefined
  ): EditorJS {
    return new EditorJS({
      /**
       * Id of Element that should contain Editor instance
       */
      holder: editorId,

      readOnly,

      /**
       * Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       * Order matters
       */
      tools: {
        // Disabled for now
        // header: {
        //   class: Header,
        //   config: {
        //     placeholder: 'Enter a header',
        //     levels: [1, 2, 3, 4],
        //     defaultLevel: 4
        //   }
        // },
        underline: Underline,
        list: {
          class: List,
          inlineToolbar: true
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3
          }
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M'
        },
        delimiter: Delimiter

        // code: CodeTool
      },

      placeholder: placeholder ?? 'Let`s write an awesome story!',

      /**
       * onChange callback
       */
      onChange: onChangeCallback,

      // @ts-ignore
      // eslint-disable-next-line consistent-return
      data: this.parsedContent,

      // @ts-ignore
      logLevel: 'ERROR'
    });
  }
}
